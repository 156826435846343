
.auth {
    text-align: center;
    margin-top: 120px;
    width: 350px;
}

.auth .btn {
    margin-top: 40px;
}

.auth .btn {
    justify-content: center;
}