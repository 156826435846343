@import "form/device.css";
@import "form/visual.css";
@import "form/tech.css";
@import "form/customer.css";
@import "form/photos.css";

.visual-form__questions {
    margin-top: 20px;
}

.request-content {
    display: flex;
    width: 100%;
}

.request-sidebar {
    min-width: 450px;
    width: 450px;
    background: #F5F1FB;
    height: 100%;
    border-radius: 0 30px 30px 0;
    display: flex;
    justify-content: flex-end;
    position: fixed;
}

.request-sidebar__inner {
    width: 255px;
    margin-right: 30px;
    padding: 60px 0 80px;
    display: flex;
    flex-direction: column;
}

.request-sidebar__logo img {
    width: 205px;
}

.request-sidebar__steps {
    margin-top: 90px;
}

.request-sidebar__device {
    color: #fff;
    background: #680A87;
    border-radius: 12px;
    padding: 15px;
    margin-top: 45px;
}

.request-sidebar__step-point {
    width: 8px;
    height: 8px;
    background: #fbae17;
    border-radius: 40px;
    margin-right: 15px;
    position: relative;
}

.request-sidebar__step {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #7F7686;
    display: flex;
    align-items: center;
}

.request-sidebar__step + .request-sidebar__step {
    margin-top: 25px;
}

.request-sidebar__price {
    display: flex;
    color: #C590D7;
    margin: 5px 0;
}

.request-sidebar__price-icon {
    margin-right: 10px;
}

.request-sidebar__device-text {
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
}

.request-sidebar__device-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
}

.request-sidebar__footer-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
}

.request-sidebar__footer-icon {
    margin-right: 20px;
}

.request-sidebar__footer-text {
    color: #9898A5;
}

.request-sidebar__footer-icon img {
    width: 25px;
}

.request-sidebar__footer {
    margin-top: auto;
}

.request-sidebar__footer-link + .request-sidebar__footer-link {
    margin-top: 15px;
}

.request-title {
    font-weight: 700;
    font-size: 36px;
    line-height: 22px;
    color: #332D3D;
    text-align: center;
}

.request-inner {
    padding: 90px 95px;
    width: 100%;
    margin-left: 450px;
    max-width: 1024px;
}

.request-form {
    margin-top: 100px;
    max-width: 1024px;
}

.request-sidebar__step.current .request-sidebar__step-text {
    font-weight: bold;
    color: #332D3D;
}

.request-sidebar__step:nth-child(n+2).active .request-sidebar__step-point:before {
    content: '';
    width: 2px;
    height: 45px;
    background: #fbae17;
    position: absolute;
    top: -40px;
    left: 3px;
}

.request-form.request-form--visual-form {
    max-width: 780px;
}

.request.request--success {
    transition: background .3s;
    background: #680a87;
}

.request.request--success .request-title {
    color: #fff;
}

.request-form.request-form--success {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.success-form__text-title {
    display: flex;
    justify-content: center;
    color: #fff;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
}

.success-form__text-sub {
    display: flex;
    justify-content: center;
    color: #fff;
    text-align: center;
    margin-top: 10px;
}

.success-form__text {
    margin-top: -40px;
}

.success-form__buttons .btn-white,
.success-form__buttons .btn-orange {
    display: flex;
    justify-content: center;
}

.success-form__buttons {
    margin-top: 70px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr auto;
    grid-gap: 20px;
    align-items: center;
    grid-template-areas: "req print" "pay pay";
}

.success-form__buttons .btn-white {
    grid-area: req;
}

.success-form__buttons .btn-transparent {
    grid-area: print;
}

.success-form__buttons .btn-orange {
    grid-area: pay;
}

.form-device {
    display: none;
}

.request-topbar {
    position: absolute;
    width: 100%;
    display: none;
    justify-content: space-between;
    padding: 0 16px;
    box-sizing: border-box;
    top: 40px;
    align-items: center;
}

.request-topbar__help img {
    height: 24px;
}

.request-topbar__back img {
    height: 24px;
}

.request-topbar__help,
.request-topbar__back {
    background: #f9fafb;
    border-radius: 12px;
    box-sizing: border-box;
    height: 36px;
    width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ld {
    margin-top: 100px;
}