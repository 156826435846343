.device-form__section-title {
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #332D3D;
    font-weight: bold;
    margin-bottom: 15px;
}

.device-form {
    width: 740px;
}

.device-form__grid {
    display: grid;
    grid-template-columns: 455px 0 255px;
    grid-template-rows: 1fr 1fr 1fr;
    grid-gap: 20px 15px;
    grid-auto-flow: row;
    grid-template-areas:
            "device device device"
            "brand brand model"
            "brand brand memory"
}

.device-form__section--device {
    grid-area: device;
}

.device-form__section--brand {
    grid-area: brand;
}

.device-form__section--model {
    grid-area: model;
}

.device-form__section--memory {
    grid-area: memory;
}

.device-form__section--search {
    grid-area: search;
}

.device-form__section--device .device-form__buttons {
    display: grid;
    grid-column-gap: 30px;
    grid-template-columns: 160px 160px 160px 160px;
}

.device-form__section--brand .device-form__buttons {
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: 95px 95px 95px 95px;
}

.device-form__brand {
    background: #F9FAFB;
    color: #333333;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 12px;
    width: 100%;
    align-items: center;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    box-sizing: border-box;
    cursor: pointer;
}

.device-form__device {
    background: #F9FAFB;
    color: #333333;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    border-radius: 12px;
    width: 100%;
    align-items: center;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    box-sizing: border-box;
    cursor: pointer;
}

.device-form__brand.active,
.device-form__device.active {
    background: #680A87;
    color: #FFFFFF;
}

.device-form__brand.active svg path:not(.text),
.device-form__device.active svg path {
    fill: white;
    stroke: white;
}


.device-form__brand.active svg path.text {
    fill: #680A87;
}
.device-form__brand-icon {
    margin-bottom: 12px;
}
.device-form__section--brand .device-form__selector__control {
    margin-top: 28px;
}

.device-form__section--model .device-form__selector__control,
.device-form__section--memory .device-form__selector__control,
.device-form__section--brand .device-form__selector__control {
    width: 100%;
    border: none;
    background: #F9FAFB;
    border-radius: 20px;
    box-sizing: border-box;
    color: #9898A5;
    position: relative;
    cursor: pointer;
}

.device-form__selector__input-container,
.device-form__selector__placeholder {
    padding: 5px 15px;

}

.device-form__search-conditions {
    display: flex;
}

.device-form__search-condition {
    display: flex;
}

.device-form__search-item {
    padding: 15px 25px;
    width: 100%;
    background: #f9fafb;
    border-radius: 12px;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
}

.device-form__search-title {
    font-weight: 700;
    font-size: 24px;
    color: #333333;
    margin-bottom: 20px;
}

.device-form__search-price {
    color: #9898A5;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-left: 8px;
}

.device-form__search-condition + .device-form__search-condition {
    margin-left: 25px;
}

.device-form__search-select_icon {
    position: absolute;
    right: 0;
    top: 0;
}


.device-form__buttons {
    display: flex;
    justify-content: center;
}

.device-form__buttons .btn {
    max-width: 255px;
    justify-content: center;
    margin: 60px 30px;
}



