
.tech-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tech-form__text {
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    /* or 141% */
    text-align: center;

    /* Text */
    color: #231F20;
    margin-top: 45px;
    width: 320px;
}

.tech-form__code-text {
    margin-top: 65px;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    /* identical to box height, or 141% */
    text-align: center;

    /* Text */
    color: #231F20;
}

.tech-form__code-code {
    font-weight: 700;
    font-size: 30px;
    line-height: 24px;
    text-align: center;
    display: flex;
    justify-content: center;
    color: #680A87;
    margin-top: 24px;
}

.tech-form__buttons {
    display: flex;
    margin-top: 55px;
}

.tech-form__buttons .btn {
    width: 255px;
    display: flex;
    justify-content: center;
}

.tech-form__qr img {
    height: 200px;
}