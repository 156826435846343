.visual-form__title {
    text-align: center;
}

.question-group__title {
    cursor: pointer;
    padding: 12px 16px 10px;
    border-radius: 12px;
    font-size: 18px;
    line-height: 20px;
    color: #333333;
    font-weight: 500;
    text-align: center;
}

.question-subgroup__title {
    cursor: pointer;
    padding: 12px 16px 10px;
    background: #F9FAFB;
    border-radius: 12px;
    font-size: 15px;
    line-height: 20px;
    color: #333333;
    font-weight: 500;
}

.question-item {
    cursor: pointer;
    padding: 12px 16px 10px;
    background: #F9FAFB;
    border-radius: 12px;
    font-size: 15px;
    line-height: 20px;
    color: #333333;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
}

.question-subgroup__inner {
    margin-left: 25px;
}

.question-subgroup,
.question-item {
    /*margin-top: 16px;*/
}

.question-subgroup .question-subgroup__title,
.question-group .question-group__title {
    /*margin-bottom: 16px;*/
}


.question-item__checkbox {
    height: 30px;
}

.question-item {
    align-items: center;
}

.question-group + .question-group {
    margin-top: 45px;
}

.question-group + .question-group:before {
    content: '';
    width: 100%;
    height: 1px;
    background: #ededed;
    position: absolute;
    top: -5px;
}

.question-group {
    position: relative;
}

.question-group__title {
    font-size: 20px;
    font-weight: 600;
}

.question-item__checkbox {
    height: 30px;
    margin-left: 40px;
}

.visual-form__buttons {
    display: flex;
    justify-content: center;
}

.visual-form__buttons .btn {
    max-width: 255px;
    justify-content: center;
    margin: 60px 30px;
}

.ReactCollapse--collapse {
    transition: all .3s;
    margin: 16px 0;
}

.question-item + .question-item {
    margin-top: 16px;
}

.question-subgroup__title--icon {
    background: #4caf50;
    display: flex;
    border-radius: 20px;
    height: 14px;
    padding: 2px;
}

.question-subgroup__title {
    display: flex;
    justify-content: space-between;
}

.question-group__values {
    margin-top: 16px;
}

.question-subgroup__title {
    font-weight: 600;
}