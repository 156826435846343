#section-to-print {
   visibility: hidden;
    overflow: hidden;
    height: 1px;
}

@media print {

    @page { margin: 0; }

    body {
        width: 210mm;
        height: 297mm;
        margin: 16mm;
    }


    body * {
        visibility: hidden;
    }
    #section-to-print,
    #section-to-print * {
        visibility: visible;
    }

    #section-to-print {
        height: initial;
    }

    #section-to-print {
        position: fixed;
        left: 10mm;
        top: 10mm;
        width: 295mm;
    }

    #section-to-print h1 {
        margin-bottom: 10mm;
    }

    #section-to-print ul {
        list-style-type: none;
        padding-left: 2mm;
    }

    #section-to-print ul li {
        font-size: 8mm;
        line-height: 16mm;
    }

    #section-to-print ul li span {
        display: inline-block;
        width: 60mm !important;
    }

    #section-to-print .barcode {
        margin-top: 10mm;
    }

}
