.checks {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 32px;
    align-items: center;
}

.check {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
}

.checkIcon img   {
    width: 32px;
    height: 32px;
    object-fit: cover;

    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 50px;
    padding: 7px;
    box-sizing: border-box;
}
.checkIconOk {
    background: #4caf50;
}

.checkIconBad {
    background: #fb1717;
}