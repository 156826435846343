@media (max-width: 768px) {
    .aside-image {
        display: none;
    }

    .aside-inner {
        width: 100%;
        padding: 0 16px;
        padding-bottom: 35px;
    }

    .aside-inner .top {
        margin-bottom: 50px;
        margin-top: 45px;
    }

    .top .top-clearfix {
        height: 22px;
    }

    .reveal, .reveal--loaded {
        transform: none;
    }

    .searcher-title {
        font-size: 24px;
        margin-bottom: 50px;
    }

    .searcher-close {
        position: absolute;
        top: 20px;
        right: 30px;
        cursor: pointer;
    }

    .auth,
    .main {
        width: 250px;
    }

    .top {
        justify-content: flex-start;
    }

    .top .top-user {
        order: 2;
        width: 100%;
        text-align: center;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
    }
    .top .top-exit {
        position: absolute;
    }
    .top-exit__text {
        display: none;
    }

    .top .top-exit__icon {
        margin-right: 0 !important;
    }

    .main-text {
        font-weight: 300;
        font-size: 15px;
        line-height: 20px;
    }

    .main .btn .text {
        font-size: 15px;
    }

    .request-sidebar {
        display: none;
    }

    .request-inner {
        margin-left: 0;
        padding: 16px;
    }

    .request-title {
        margin-top: 30px;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
    }

    .device-form__grid {
        grid-template-rows: auto auto auto;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
                "device device"
                "brand brand"
                "model model"
                "memory memory";
    }

    .device-form__section--search {
        margin-top: 35px;
    }

    .device-form__section--device .device-form__section-title {
        display: none;
    }

    .device-form__section--device .device-form__buttons {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-gap: 16px;
    }

    .device-form {
        width: 100%;
    }

    .device-form__brand {
        padding: 10px;
    }

    .device-form__brand-icon {
        margin-bottom: 6px;
    }

    .device-form__section--brand .device-form__buttons {
        grid-template-columns: 1fr 1fr  1fr 1fr;
    }

    .request-form {
        margin-top: 35px;
    }

    .result-pp {
        position: fixed;
        z-index: 100;
        bottom: 0;
        width: 100%;
        left: 0;
        padding: 32px 64px;
        box-sizing: border-box;
        border-radius: 30px 30px 0px 0px;
        background: #680A87;
        animation: slidein 0.5s;
    }

    @keyframes slidein {
        from {
           transform: translateY(100%);
        }

        to {
            transform: translateY(0);
        }
    }

    .result__smiley img {
        width: 64px;
    }

    .result__text {
        font-size: 16px;
        font-weight: bold;
        margin-top: 20px;
    }

    .result__buttons {
        flex-direction: column;
    }

    .result__buttons .btn {margin: 10px 0;}

    .result__price-disclamer {
        font-size: 14px;
    }

    .result__text,
    .result__price-title,
    .result__price-disclamer,
    .result__price-amount {
        color: #fff;
    }

    .result__text {
        width: 100%;
    }

    input.customer-confirm-form__code-block {
        height: 50px !important;
        width: 50px !important;
    }

    .success-form__image img {
        width: 100%;
    }

    .success-form__text-title {
        width: 50%;
        font-size: 18px;
    }

    .success-form__text {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .success-form__buttons {
        flex-direction: column;
        align-items: center;
    }

    .success-form__buttons .btn {
        margin: 10px 0;
        max-width: 70%;
    }

    .request-topbar {
        display: flex;
    }

    .request-sidebar__device-prices {
        display: flex;
        justify-content: space-between;
    }

    .form-device {
        display: block;
        margin-bottom: 20px;
    }

    .request-sidebar__device {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .instruction {
        background: #fff;
        width: 100%;
        height: 100%;
        overflow: auto;
    }

    .instruction-container {}

    .instruction-items {
        flex-direction: column;
    }

    .instruction-item {
        max-width: 100%;
        background: #F9FAFB;
        flex-wrap: wrap;
        flex-direction: row;
        border-radius: 5px;
        padding: 10px;
        margin-bottom: 10px;
    }

    .instruction-item__icon img {
        width: 24px;
    }

    .instruction-item__icon {
        order: 2;
        width: 10%;
    }

    .instruction-item__title {
        width: 90%;
        margin-top: unset;
        color: #680A87;
    }

    .instruction-item__description {
        order: 3;
        color: #333;
        text-align: left;
    }

    .instruction-title {
        color: #363538;
        font-size: 22px;
        margin-bottom: 30px;
    }

    .instruction-container {
        justify-content: flex-start;
        margin-top: 45px;
    }

    .instruction-close {
        right: 20px;
        top: 20px;
        filter: invert(1);
    }

    .instruction-close img {
        width: 24px;
    }

    form.photos-form__holder {
        grid-template-columns: 255px;
    }

    .request-sidebar__device-prices {
        flex-wrap: wrap;
    }
    .request-sidebar__device-prices > div {
        width: 33%;
    }

    .request-sidebar__device-prices > div:last-of-type {
        width: 66%;
    }

    .device-form__search-conditions {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px 0;
    }

    .device-form__search-condition + .device-form__search-condition {
        margin-left: initial;
    }

    .visual-form__buttons .btn-white {
        display: none;
    }
}
