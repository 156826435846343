.request-form.request-form--customer-form {
    display: flex;
    justify-content: center;
}

.customer-form__inputs {
    width: 350px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.customer-form__checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    background: #f9fafb;
    width: 185px;
    border-radius: 12px;
    margin: 20px 0;
    cursor: pointer;
}

.customer-confirm-form__code {
    display: flex;
}



input.customer-confirm-form__code-block:not(:placeholder-shown)  {
    background: #680A87;
    color: #fff;
    box-shadow: -1px 4px 9px 0px #ce9ddf;
}

input.customer-confirm-form__code-block {
    font-weight: bold;
    max-width: 75px !important;
    width: 75px !important;
    height: 75px !important;
    text-align: center;
    font-size: 24px;
    background: #F9FAFB;
    border-radius: 6px;
    border: none;
    margin: 0 10px;
}

.customer-form__inputs .input {
    width: 100%;
}

.customer-form__checkbox-icon {
    height: 26px;
}


.customer-form__buttons {
    display: flex;
    margin-top: 120px;
    justify-content: center;
}

.customer-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.customer-form__buttons .btn {
    width: 255px;
    display: flex;
    justify-content: center;
}

.customer-confirm-form__text {
    font-weight: 400;
    font-size: 21px;
    line-height: 140%;
    text-align: center;
    color: #231F20;
    width: 300px;
    margin-bottom: 24px;
}

.customer-confirm-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.customer-confirm-form__phone {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #9898A5;
    margin-bottom: 55px;
}

.customer-confirm-form__buttons {
    margin-top: 120px;
}

.customer-confirm-form__send {
    margin-top: 45px;
}

.customer-confirm-form__send-button .btn {
    display: flex;
    justify-content: center;
}

.customer-confirm-form__send-text {
    margin-bottom: 10px;
    text-align: center;
    font-weight: 500;
}

.customer-confirm-form__buttons .btn {
    width: 255px;
    display: flex;
    justify-content: center;
}

.customer-confirm-form__send-button .btn {
    width: 255px;
}