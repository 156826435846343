.instruction {
    position: fixed;
    background: #680a87;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    display: none;
    transition: opacity .3s;
}

.instruction.show {
    display: block;
}

.instruction.open {
    opacity: 1;
}

.instruction-items {
    display: flex;
    justify-content: center;
}

.instruction-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.instruction-item__title {
    color: #fff;
    margin-top: 40px;
    font-size: 21px;
    line-height: 22px;
    font-weight: bold;
}

.instruction-item__description {
    color: #fff;
    margin-top: 20px;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
}

.instruction-item {
    max-width: 255px;
    margin: 0 15px;
}

.instruction-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-top: -30px;

}

.instruction-close {
    position: absolute;
    top: 100px;
    right: 200px;
    cursor: pointer;
}

.instruction-title {
    font-weight: 700;
    font-size: 36px;
    line-height: 22px;
    text-align: center;
    color: #D4A3E0;
    margin-bottom: 80px;
}