.top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 100px;
}

.top .top-user {
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: #333333;
}

.top .top-exit {
    background: #F9FAFB;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    padding: 12px 16px;
    align-items: center;
}

.top .top-exit__icon img {
    height: 20px;
}

.top .top-exit__icon {
    margin-right: 16px;
}

.top .top-exit__text {
    font-weight: 300;
    font-size: 13px;
    line-height: 16px;
    color: #9898A5;
}

.top .top-clearfix {
    height: 48px;
}

.top-exit {
    cursor: pointer;
}