.request-form.request-form--result {
    margin-top: 160px;
    display: flex;
    justify-content: center;
}

.result {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.result__text {
    font-weight: 400;
    font-size: 21px;
    line-height: 140%;
    /* or 29px */
    text-align: center;

    /* Text */
    color: #231F20;
    margin-top: 50px;
    width: 340px;
}

.result__price-title {
    font-size: 18px;
    line-height: 30px;
    /* or 167% */
    text-align: center;

    /* Text */
    color: #231F20;
    margin-top: 45px;
}

.result__price-amount {
    font-weight: bold;
    color: #680A87;
    text-align: center;
    font-size: 45px;
}

.result__price-disclamer {
    font-weight: 400;
    font-size: 15px;
    line-height: 140%;
    /* or 21px */
    text-align: center;

    /* Text */
    color: #231F20;
    margin-top: 24px;
}

.result__buttons {
    display: flex;
    margin-top: 55px;
}

.result__buttons .btn {
    width: 250px;
    display: flex;
    justify-content: center;
    margin: 0 30px;
}