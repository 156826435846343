@import "ui/forms.css";
@import "ui/button.css";
@import "ui/dropdown.css";
@import "ui/loader.css";

html, body {
    margin: 0;
    font-family: 'Qanelas', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #FFF;
    font-feature-settings: 'pnum' on, 'lnum' on;
}

body {
    overflow-x: hidden;
    color: #363538;
}

.aside, .request {
    display: flex;
    min-height: 100vh;
}

.aside-image {
    width: 100%;
    background: #680A87;
    background-image: url("../img/aside.svg");
    background-repeat: no-repeat;
    background-position: center left;
    background-size: 85%;
    min-width: 50vw;
}

.aside-content {
    width: 100%;
    display: flex;
}

.aside-inner {
    width: 350px;
    padding-left: 125px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.aside-inner .top {
    margin-bottom: 135px;
}


.reveal {
    transition: all .3s;
    transform: translateY(-30px);
    opacity: 0;
}


.reveal--loaded {
    transform: translateY(0px);
    opacity: 1;
}

textarea:focus, input:focus{
    outline: none;
}