
.main {
    text-align: center;
    width: 350px;
}


.main-logo {
    margin-top: 55px;
}


.main .btn .text {
    font-size: 18px;
}

.main .btn + .btn {
    margin-top: 32px;
}

.main-text {
    font-weight: 300;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
    color: #231F20;
    margin-bottom: 50px;
}