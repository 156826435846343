.input input {
    border: none;
    background: #F9FAFB;
    width: 100%;
    border-radius: 44px;
    font-size: 15px;
    padding: 15px 30px;
    box-sizing: border-box;
    text-align: center;
}

.input + .input {
    margin-top: 30px;
}

.input input::placeholder {
    text-align: center;
}

.input input:focus {
    border: none;
    outline: none;
}

