.btn {
    border: none;
    width: 100%;
    border-radius: 44px;
    font-size: 15px;
    padding: 10px 20px;
    height: 50px;
    box-sizing: border-box;

    font-weight: 500;
    line-height: 20px;
    text-align: center;

    cursor: pointer;

    display: flex;
    justify-content: space-between;
    align-items: center;
}
a.btn {
    text-decoration: none;
}

.btn .icon img {
    height: 26px;
}

.btn.btn-orange {
    background: #FBAE17;
    color: #fff;
}

.btn.btn-white {
    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
    border-radius: 36px;
    color: #680A87;
}

.btn.btn-purple {
    background: #680A87;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
    border-radius: 36px;
    color: #fff;
}

.btn.btn-transparent {
    background: none;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
    border-radius: 36px;
    border: 1px solid #fff;
    color: #fff;
}
