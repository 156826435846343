.searcher {
    position: fixed;
    background: #fff;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    display: none;
    transition: opacity .3s;
}

.searcher.show {
    display: block;
}

.searcher-close {

}


.searcher-close {
    filter: invert(1);
}

.searcher.open {
    opacity: 1;
}

.searcher-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-top: -30px;

}

.searcher-close {
    position: absolute;
    top: 100px;
    right: 200px;
    cursor: pointer;
}

.searcher-title {
    font-weight: 700;
    font-size: 36px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 80px;
}

.searcher-buttons {
    margin-top: 40px;
    width: 220px;
}

.searcher-buttons .btn {
    display: flex;
    justify-content: center;
}