form.photos-form__holder {
    display: grid;
    grid-template-columns: 255px 255px;
    grid-gap: 30px;
    text-align: center;
    margin-top: 40px;
}

.photos-form__photo {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 255px;
    /* justify-content: center; */
}

input[type="file"] {
    width: 1px;
    height: 1px;
}

.photos-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.photos-form__photo-zone {
    background: #F9FAFB;
    border: 1px dashed #680A87;
    box-sizing: border-box;
    border-radius: 20px;
    width: 100%;
    height: 145px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.photos-form__photo-title {
    margin-top: 8px;
}

.photos-form__photo-zone > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}

.photos-form__photo-zone {
    position: relative;
}


.photos-form__text--desktop {
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    /* or 141% */
    text-align: center;

    /* Text */
    color: #231F20;
    margin-top: 45px;
    width: 320px;
}

.photos-form__code-text {
    margin-top: 65px;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    /* identical to box height, or 141% */
    text-align: center;

    /* Text */
    color: #231F20;
}

.photos-form__code-code {
    font-weight: 700;
    font-size: 30px;
    line-height: 24px;
    text-align: center;
    display: flex;
    justify-content: center;
    color: #680A87;
    margin-top: 24px;
}

.photos-form__buttons {
    display: flex;
    margin-top: 55px;
    justify-content: center;
}

.photos-form__buttons .btn {
    width: 255px;
    display: flex;
    justify-content: center;
}
