.dropdown {
    width: 100%;
    border: none;
    background: #F9FAFB;
    border-radius: 20px;
    box-sizing: border-box;
    color: #9898A5;
    position: relative;
    cursor: pointer;
}

.dropdown__value {
    display: flex;
    justify-content: space-between;
}


.dropdown.dropdown--open {
    border-radius: 20px 20px 0 0;
}

.dropdown--open .dropdown__value {
    border-bottom: 1px solid #D4DAE0;
}

.dropdown__value-icon {
    transition: all .3s;
}

.dropdown--open .dropdown__value-icon {

    transform: rotate(180deg);
}

.dropdown__options {
    display: none;
    position: absolute;
    background: #F9FAFB;
    width: 100%;
    border-radius: 0 0 20px 20px;
    z-index: 10;
    max-height: 300px;
    overflow: auto;
}

.dropdown__options::-webkit-scrollbar {
    width: 1px;
}

.dropdown--open .dropdown__options {
    display: block;
}

.dropdown__value,
.dropdown__option {
    padding: 10px 15px;
}

.dropdown__option + .dropdown__option {
    border-top: 1px solid #D4DAE0;
}